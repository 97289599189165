import {ListInfo} from '@/types/ListInfo';
import MinimalArticle from '@/domains/article/MinimalArticle';
import NormalizedData from '@/types/NormalizedData';
import Article from '@/domains/article/Article';

export interface ArticleState {
  articles: NormalizedData<Article>;
  minimalArticles: NormalizedData<MinimalArticle>;
  listInfo: ListInfo;
  cloneArticle?: Article;
}

const state: ArticleState = {
  articles: {},
  minimalArticles: {},
  listInfo: ListInfo.create(),
  cloneArticle: undefined
};

export default state;
